import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { FarmUnit } from '@/models/swine/farmUnit/farmUnit.model';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'farm-units.list',
  store,
})
class FarmUnitListStore extends VuexModule {
  response: FarmUnit[] | null = null;
  isLoading = false;
  error: any | null = null;
  farmId: string | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: FarmUnit[]) {
    this.response = payload;
  }

  @Mutation
  setFarmId(farmId: string) {
    this.farmId = farmId;
  }

  @Action
  getAllFarmUnitsByFarmId(farmId: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.context.commit(`setFarmId`, farmId);
      if (this.farmId) {
        axios
          .get(`${Endpoints.FarmUnits}`, {
            params: {
              farm: this.farmId,
            },
          })
          .then((response) => {
            this.context.commit('setResponse', response.data);
          });
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(FarmUnitListStore);
