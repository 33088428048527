










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { MONTHS } from '@/utils/constants';
import { DropdownOption } from '@/models/swine/dropdown.model';
@Component({
  components: {
    Dropdown,
  },
})
export default class MonthDropdown extends Vue {
  months: DropdownOption[] = [];
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;
  displayValue = 'Month';

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  mounted() {
    this.getData();
  }

  createBaseData() {
    this.months = [];
  }

  getData() {
    if (!this.disabled) {
      this.createBaseData();
      MONTHS.map((data) => {
        this.months.push(data);
      });
    }
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Month';
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }

  @Emit(`select`)
  selectMonth(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }
}
