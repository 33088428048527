










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { FarmStores } from '@/store/swine/farms/index';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Farm } from '@/models/swine/farm/farm.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    Dropdown,
  },
})
export default class FarmDropdown extends Vue {
  store = FarmStores.list;
  farms: DropdownOption[] = [];
  @Prop({
    type: Boolean,
    default: true,
  })
  disabled!: boolean;

  @Prop({
    default: null,
    type: String,
  })
  clientId!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  displayValue = 'Farm';

  mounted() {
    this.createBaseData();
  }

  createBaseData() {
    this.farms = [];
    this.farms.push({
      text: `Farm`,
      value: `-1`,
      uniqueKey: generateUniqueId(`farm_`),
    });
  }

  @Watch(`disabled`)
  @Watch(`clientId`)
  getData() {
    if (this.clientId && !this.disabled) {
      this.store.getAllFarmsByClientId(this.clientId);
    }
  }

  @Watch(`store.response`)
  getFarms() {
    if (this.store.response && this.farms) {
      this.farms = [];
      this.store.response.map((data: Farm) => {
        this.farms.push({
          text: data.farmCode,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.name}_`),
        });
      });
    }
  }

  @Emit(`select`)
  selectFarm(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Farm';
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }
}
