
























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import SearchBar from '@/components/swine/SearchBar.vue';
import MonthDropdown from '@/components/swine/dropdowns/MonthDropdown.vue';
import YearDropdown from '@/components/swine/dropdowns/YearDropdown.vue';
import ExportReportSplitDropdown from '@/components/swine/dropdowns/ExportReportDropdown.vue';
import {
  DEFAULT_COUNT_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
  EXPORT_REPORT_OPTION,
  REPORT_TYPE,
  Role,
} from '@/utils/constants';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';
import {
  AuditReportDisplayValues,
  AuditReportListResponse,
  AuditReportSearchParams,
  ExcelReportData,
  ReportTypeStatus,
} from '@/models/swine/report/report.model';
import { ReportStores } from '@/store/swine/reports';
import MockReportTable from './MockReportTable.vue';
import moment from 'moment';
import { DropdownOption } from '@/models/swine/dropdown.model';
import DeleteReportModal from '@/components/swine/reports/DeleteReportModal.vue';
import { downloadPdf } from '@/utils/download-pdf/download-pdf';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { exportReportExcel } from '@/utils/export-excel/export-excel';
import { UserSessionDetails } from '@/models/swine/user/user.model';
import { ProfileStores } from '@/store/swine/profile/index';
import { exportSummaryReportExcel } from '../../../utils/export-excel/export-excel';
import { ExportSummaryReportData } from '../../../models/swine/report/report.model';

@Component({
  components: {
    InnerPageTemplate,
    SearchBar,
    MonthDropdown,
    YearDropdown,
    MockReportTable,
    ExportReportSplitDropdown,
    DeleteReportModal,
  },
})
export default class MockReportManagement extends Vue {
  search: string | null = null;
  keys = [`month`, `year`];
  queries: { [key: string]: string | null } = {};
  monthKey = this.keys[0];
  yearKey = this.keys[1];
  store = ReportStores.list;
  sortBy = 'publishedDate:desc';
  totalMockReports = 0;
  filteredMockReports = 0;
  offsetValue = 0;
  currentPageNumber = DEFAULT_PAGE_NUMBER;
  currentRowsPerPage = DEFAULT_COUNT_PER_PAGE;
  mockReports: AuditReportDisplayValues[] = [];
  resetDetails: { [key: string]: boolean | null } = {};
  areInvalidReportsSelected = false;
  exportReportOptions: DropdownOption[] = [];
  exportReportBorderColor = `#0072CE`;
  isClearAllFiltersAvailable = false;
  selectedMockReports: AuditReportDisplayValues[] = [];
  resetSelectedMockReports = false;
  isModalActive = false;
  reportType = ReportTypeStatus.MOCK;
  currentRole = '';
  currentCountry = '';
  currentEmail = '';
  isCurrentRoleViewer = false;
  userProfileStore = ProfileStores.profile;
  @Prop({ required: true })
  userSession!: UserSessionDetails;

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      // Set up the dictionary
      this.currentRole = this.userProfileStore.userProfile.role;
      this.isCurrentRoleViewer =
        this.currentRole === Role.Viewer ? true : false;
      this.currentCountry = this.userProfileStore.userProfile.country
        ? this.userProfileStore.userProfile.country.code
        : '';
      this.currentEmail = this.userProfileStore.userProfile.email;
      this.exportReportOptions = EXPORT_REPORT_OPTION;
      this.keys.map((key) => {
        this.queries[key] = null;
      });
      this.retrieveMockReports();
    }
  }

  deleteSelectedMockReports() {
    this.isModalActive = false;
    const reportIds: number[] = [];
    for (const report of this.selectedMockReports) {
      reportIds.push(report.id);
    }
    this.store.deleteSpecifiedReports(reportIds);
    this.resetSelectedMockReports = true;
  }

  @Watch('store.deleteResponse')
  retrieveNewReports() {
    this.retrieveMockReports();
  }

  showClearAllFilters() {
    this.isClearAllFiltersAvailable = false;
    this.keys.forEach((key) => {
      if (this.queries[key]) {
        this.isClearAllFiltersAvailable = true;
      }
    });
    if (this.selectedMockReports.length > 0) {
      this.isClearAllFiltersAvailable = true;
    }
  }

  clearAllFilters() {
    this.keys.forEach((key) => {
      this.resetDetails[key] = true;
      this.queries[key] = null;
    });
    this.search = null;
    this.resetSelectedMockReports = true;
    this.retrieveMockReports();
  }

  updateSelectedItems(reports: AuditReportDisplayValues[]) {
    this.selectedMockReports = reports;
    this.areInvalidReportsSelected = false;
    if (this.currentRole === Role.Regional) {
      this.areInvalidReportsSelected = false;
    } else if (this.currentRole === Role.Admin) {
      this.selectedMockReports.forEach((report) => {
        if (report.country !== this.currentCountry) {
          this.areInvalidReportsSelected = true;
        }
      });
    } else if (this.currentRole === Role.Auditor) {
      this.areInvalidReportsSelected = true;
    } else if (this.currentRole === Role.Viewer) {
      this.areInvalidReportsSelected = true;
    }
    this.showClearAllFilters();
  }

  searchReports(searchQuery: string) {
    this.search = null;
    if (searchQuery.length > 0) {
      this.search = searchQuery;
    }
    this.retrieveMockReports();
  }

  updateMonthId(value: string) {
    this.resetQueryValues(0);
    if (value.length > 0) {
      this.queries[this.monthKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveMockReports();
  }

  updateYearId(value: string) {
    this.resetQueryValues(1);
    if (value.length > 0) {
      this.queries[this.yearKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveMockReports();
  }

  resetQueryValues(keyIndex: number) {
    for (let index = keyIndex; index < this.keys.length; index++) {
      this.queries[this.keys[index]] = null;
    }
  }

  updateResetMonthDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.monthKey] = null;
    }
  }

  updateResetYearDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.yearKey] = null;
    }
  }

  sortTable(sort: string) {
    this.sortBy = sort;
    this.retrieveMockReports();
  }

  updateRowCount(params: RowCountChange) {
    this.currentRowsPerPage = params.rowsPerPage;
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = params.offsetValue;
    this.retrieveMockReports();
  }

  updatePageNumber(params: PageNumberChange) {
    this.currentPageNumber = params.pageNumber;
    this.offsetValue = params.offsetValue;
    this.retrieveMockReports();
  }

  async selectExportReportOption(option: DropdownOption) {
    // Export PDF
    if (option.value === 'export_pdf') {
      const reportPDFPromises: Promise<any>[] = [];
      reportPDFPromises.concat(
        this.selectedMockReports.map((report) => {
          return downloadPdf(report.id.toString());
        })
      );
      await Promise.all(reportPDFPromises);
    } else if (option.value === 'export_excel') {
      //Export Excel
      const reportIDStrings: string[] = [];
      for (const report of this.selectedMockReports) {
        reportIDStrings.push(`${report.id}`);
      }
      const response = await axios.get(`${Endpoints.Reports}/swine`, {
        params: {
          report: reportIDStrings.join(','),
        },
      });
      const reports: ExcelReportData[] = response.data;
      const isMockReport = true;
      exportReportExcel(reports, isMockReport);
    } else if (option.value === 'export_summary_excel') {
      // Export Excel summary
      const reportIDStrings: string[] = [];
      for (const report of this.selectedMockReports) {
        reportIDStrings.push(`${report.id}`);
      }
      const response = await axios.get(`${Endpoints.Reports}/swine`, {
        params: {
          report: reportIDStrings.join(','),
          exportType: option.value,
        },
      });
      const reports: ExportSummaryReportData[] = response.data;
      const isMockReport = false;
      exportSummaryReportExcel(reports, isMockReport);
    }
  }

  retrieveMockReports() {
    if (this.queries) {
      const baseSearchParams: AuditReportSearchParams = {
        limit: this.currentRowsPerPage,
        offset: this.offsetValue,
        sortBy: this.sortBy,
        type: REPORT_TYPE.MOCK,
        month: this.queries[this.monthKey],
        year: this.queries[this.yearKey],
        query: this.search,
      };
      this.store.getAllMockAuditReports(baseSearchParams);
    }
  }

  @Watch('store.mockResponse')
  updateInformation() {
    if (this.store.mockResponse) {
      this.filteredMockReports = this.store.mockResponse.filteredCount;
      this.totalMockReports = this.store.mockResponse.totalCount;
      this.mockReports = [];
      this.store.mockResponse.reports.map(
        (details: AuditReportListResponse) => {
          this.mockReports.push({
            auditDateTime: moment(details.publishedDate)
              .local()
              .format('DD/MM/YYYY hh:mm A'),
            id: details.id,
            index: details.index,
            totalLungsObserved: details.reportValues.totalNumberOfLungs.length,
            auditorEmail: details.user.email as string,
            auditorName: details.user.userProfile
              ? details.user.userProfile.name
              : '',
            reportName: details.reportValues.reportName,
            country:
              typeof details.user.country !== 'string' && details.user.country
                ? details.user.country.code
                : '',
          });
        }
      );
    }
  }

  @Emit(`return`)
  returnBackToOrigin() {
    return;
  }
}
