import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { Region } from '@/models/swine/regions/region.model';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'region.list',
  store,
})
class RegionListStore extends VuexModule {
  response: Region[] | null = null;
  isLoading = false;
  error: any | null = null;
  countryId: string | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: Region[]) {
    this.response = payload;
  }

  @Mutation
  setCountryId(countryId: string) {
    this.countryId = countryId;
  }

  @Action
  getAllRegionByCountryId(countryId: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      if (countryId) {
        this.context.commit(`setCountryId`, countryId);
        axios
          .get(`${Endpoints.Regions}`, {
            params: {
              country: this.countryId,
            },
          })
          .then((response) =>
            this.context.commit('setResponse', response.data),
          );
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(RegionListStore);
