










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { FarmUnitStores } from '@/store/swine/farm-units/index';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { FarmUnit } from '@/models/swine/farmUnit/farmUnit.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    Dropdown,
  },
})
export default class FarmUnitDropdown extends Vue {
  store = FarmUnitStores.list;
  farmUnits: DropdownOption[] = [];
  displayValue = 'Farm Unit';
  @Prop({
    type: Boolean,
    default: true,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  @Prop({
    default: null,
    type: String,
  })
  farmId!: string;

  mounted() {
    this.createBaseData();
  }

  createBaseData() {
    this.farmUnits = [];
    this.farmUnits.push({
      text: `Farm Unit`,
      value: `-1`,
      uniqueKey: generateUniqueId(`farm_unit_`),
    });
  }

  @Watch(`disabled`)
  @Watch(`farmId`)
  getData() {
    if (this.farmId && !this.disabled) {
      this.store.getAllFarmUnitsByFarmId(this.farmId);
    }
  }

  @Watch(`store.response`)
  getFarmUnits() {
    if (this.store.response && this.farmUnits) {
      this.farmUnits = [];
      this.store.response.map((data: FarmUnit) => {
        this.farmUnits.push({
          text: data.farmUnitCode,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.farmUnitCode}_`),
        });
      });
    }
  }

  @Emit(`select`)
  selectFarmUnit(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Farm Unit';
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }
}
