import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { Farm } from '@/models/swine/farm/farm.model';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'farm.list',
  store,
})
class FarmListStore extends VuexModule {
  response: Farm[] | null = null;
  isLoading = false;
  error: any | null = null;
  clientId: string | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: Farm[]) {
    this.response = payload;
  }

  @Mutation
  setClientId(clientId: string) {
    this.clientId = clientId;
  }

  @Action
  getAllFarmsByClientId(clientId: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.context.commit(`setClientId`, clientId);
      if (this.clientId) {
        axios
          .get(`${Endpoints.Farms}`, {
            params: {
              client: this.clientId,
            },
          })
          .then((response) =>
            this.context.commit('setResponse', response.data),
          );
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(FarmListStore);
