










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { BASE_YEAR, YEAR_LIMIT } from '@/utils/constants';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { generateUniqueId } from '@/utils/lodash';
@Component({
  components: {
    Dropdown,
  },
})
export default class YearDropdown extends Vue {
  baseYear = BASE_YEAR;
  yearLimit = YEAR_LIMIT;
  years: DropdownOption[] = [];
  displayValue = 'Year';
  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  mounted() {
    this.getData();
  }

  createBaseData() {
    this.years = [];
  }

  getData() {
    this.createBaseData();
    const endYear = this.baseYear + this.yearLimit;
    if (!this.disabled && this.years) {
      while (this.baseYear < endYear) {
        this.years.push({
          text: String(this.baseYear),
          value: String(this.baseYear),
          uniqueKey: generateUniqueId(`${this.baseYear}_`),
        });
        this.baseYear++;
      }
    }
  }

  @Emit(`select`)
  selectYear(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Year';
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }
}
