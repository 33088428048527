



























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import TableLayout from '@/components/swine/layout/TableLayout.vue';
import Pagination from '@/components/swine/Pagination.vue';
import { AuditReportListResponse } from '@/models/swine/report/report.model';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';

@Component({
  components: {
    TableLayout,
    Pagination,
  },
})
export default class ReportTable extends Vue {
  @Prop({
    type: Array,
    default: null,
  })
  reportList!: AuditReportListResponse[];

  @Prop({
    type: Number,
    default: 0,
  })
  totalCount!: number;

  @Prop({
    type: Number,
    default: 1,
  })
  pageNumber!: number;

  @Prop({
    type: Number,
    default: 10,
  })
  rowsPerPage!: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  isSelectionReset!: boolean;

  selectedReports: { [key: number]: boolean | null } = {};
  selectedItems: AuditReportListResponse[] = [];
  currentCounter = 0;

  @Watch('isSelectionReset')
  @Emit('reset')
  resetSelection() {
    if (this.isSelectionReset) {
      this.selectedItems.forEach((report) => {
        this.selectedReports[report.id] = null;
      });
      this.selectedItems = [];
      this.currentCounter++;
      this.returnSelectedItems();
      return false;
    }
  }

  mounted() {
    this.reportList.forEach((details) => {
      this.selectedReports[details.id] = false;
    });
  }

  @Emit('sort')
  sortPressed(field: string, order: 'asc' | 'desc') {
    return `${field}:${order}`;
  }

  @Emit('rowCountChange')
  updateRowCount(params: RowCountChange) {
    return params;
  }

  @Emit('pageNumberChange')
  updatePageNumber(params: PageNumberChange) {
    return params;
  }

  viewAuditReportSummary(id: number) {
    this.$router.push({
      name: `ReportDetails`,
      params: {
        reportId: String(id),
      },
    });
  }

  selectItem(selectedProp: AuditReportListResponse, isSelected: boolean) {
    if (isSelected) {
      this.selectedReports[selectedProp.id] = isSelected;
      this.selectedItems.push(selectedProp);
    } else {
      this.selectedItems = this.selectedItems.filter((details) => {
        return details.id !== selectedProp.id;
      });
    }
    this.returnSelectedItems();
  }
  
  @Emit('select')
  returnSelectedItems() {
    return this.selectedItems;
  }

  selectAllCurrentPageRows(){
    this.currentCounter++;
      this.reportList.forEach((item) => {
        if(!this.selectedReports[item.id])
        {
          this.selectedReports[item.id] = true;
          this.selectedItems.push(item);
        }
    });
    this.returnSelectedItems();
  }
}
