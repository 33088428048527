










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { ClientStores } from '@/store/swine/client';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { FetchClientsRequestParams } from '@/store/swine/client/client.list';
import { Client } from '@/models/swine/client/client.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    Dropdown,
  },
})
export default class ClientDropdown extends Vue {
  store = ClientStores.list;
  displayValue = 'Client';
  clients: DropdownOption[] = [];

  @Prop({
    type: Boolean,
    default: true,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  countryId!: string;

  @Prop({
    type: String,
    default: null,
  })
  stateId!: string;

  @Prop({
    type: String,
    default: null,
  })
  regionId!: string;

  mounted() {
    this.createBaseData();
  }

  get isClientsEmpty() {
    const index = this.clients.findIndex((detail) => {
      return detail.text === 'Client';
    });
    if (index > -1) {
      return true;
    }
    return false;
  }

  createBaseData() {
    this.clients = [];
    this.clients.push({
      text: `Client`,
      value: `-1`,
      uniqueKey: generateUniqueId(`client_`),
    });
  }

  @Watch(`disabled`)
  @Watch(`stateId`)
  getData() {
    if (this.countryId && this.regionId && this.stateId && !this.disabled) {
      const species = (this.$router.currentRoute.fullPath.split('/'))[1];

      const params: FetchClientsRequestParams = {
        country: this.countryId,
        state: this.stateId,
        region: this.regionId,
        species
      };
      this.store.getClients(params);
    }
  }

  @Watch(`store.response`)
  getClients() {
    if (this.store.response && this.store.response.length > 0) {
      this.clients = [];
      this.store.response.map((data: Client) => {
        this.clients.push({
          text: data.clientCode,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.name}_`),
        });
      });
    }
  }

  @Emit(`select`)
  selectClient(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Client';
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }
}
