

































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import FormTemplate from '@/components/swine/layout/FormTemplate.vue';
import { FormStyling } from '@/models/swine/form/form.model';
import {
  AuditReportDisplayValues,
  ReportTypeStatus,
} from '@/models/swine/report/report.model';
@Component({
  components: {
    FormTemplate,
  },
})
export default class DeleteReportModal extends Vue {
  formTitle = '';
  formStyling: FormStyling = {
    height: '270px',
    borderRadius: '20px',
    width: '680px',
  };

  @Prop({
    type: Array,
    default: null,
  })
  selectedReports!: AuditReportDisplayValues[];

  @Prop({})
  reportType!: string;

  mounted() {
    if (this.reportType === ReportTypeStatus.NORMAL) {
      this.formTitle =
        this.selectedReports.length > 1
          ? 'Delete Multiple Reports?'
          : `Delete Report ${this.selectedReports[0].farmUnitCodeName} ?`;
    } else if (this.reportType === ReportTypeStatus.MOCK) {
      this.formTitle =
        this.selectedReports.length > 1
          ? 'Delete Multiple Mock Reports?'
          : `Delete Report ${this.selectedReports[0].reportName} ?`;
    }
  }

  @Emit('close')
  closeForm(value: boolean) {
    return value;
  }

  @Emit('close')
  closeModal() {
    return false;
  }

  @Emit('delete')
  deleteReports() {
    return false;
  }
}
