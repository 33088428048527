
















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { EXPORT_REPORT_OPTION } from '@/utils/constants';
import { generateUniqueId } from '@/utils/lodash';

@Component({})
export default class ExportReportSplitDropdown extends Vue {
  isActive = false;
  selected: DropdownOption | null = null;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  options!: DropdownOption[];

  @Prop({
    type: String,
    default: `#8e8e8e`,
  })
  borderColor!: string;

  get borderColorTheme() {
    return `color: ${this.borderColor};`;
  }

  @Emit('select')
  handleSelected(data: DropdownOption) {
    this.isActive = !this.isActive;
    return data;
  }

  created() {
    this.initializeDefaultOption();
  }

  initializeDefaultOption() {
    this.selected = {
      text: `Export Report`,
      value: `export_pdf`,
      uniqueKey: generateUniqueId(`export_pdf_`),
    };
  }

  @Emit('select')
  spawnExportPDFSelection() {
    // Return the first value which is the
    const index = EXPORT_REPORT_OPTION.findIndex((item) => {
      return item.value === 'export_pdf';
    });
    return EXPORT_REPORT_OPTION[index];
  }
}
