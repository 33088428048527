

















































































































































/*
eslint-disable @typescript-eslint/no-var-requires
*/
import Page from '@/components/swine/layout/Page.vue';
import SearchBar from '@/components/swine/SearchBar.vue';
import Dropdown from '@/components/swine/Dropdown.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  DEFAULT_COUNT_PER_PAGE,
  DEFAULT_PAGE_NUMBER,
  EXPORT_REPORT_OPTION,
  REPORT_TYPE,
  Role,
} from '@/utils/constants';
import {
  RowCountChange,
  PageNumberChange,
} from '@/models/swine/pagination.model';
import { ReportStores } from '@/store/swine/reports';
import {
  AuditReportDisplayValues,
  AuditReportSearchParams,
  ExcelReportData,
  ReportTypeStatus,
} from '@/models/swine/report/report.model';
import ReportTable from './ReportTable.vue';
import ClientDropdown from '@/components/swine/dropdowns/ClientDropdown.vue';
import CountryDropdown from '@/components/swine/dropdowns/CountryDropdown.vue';
import FarmDropdown from '@/components/swine/dropdowns/FarmDropdown.vue';
import FarmUnitDropdown from '@/components/swine/dropdowns/FarmUnitDropdown.vue';
import MonthDropdown from '@/components/swine/dropdowns/MonthDropdown.vue';
import RegionDropdown from '@/components/swine/dropdowns/RegionDropdown.vue';
import StateDropdown from '@/components/swine/dropdowns/StateDropdown.vue';
import YearDropdown from '@/components/swine/dropdowns/YearDropdown.vue';
import MockReportManagement from './MockReportManagement.vue';
import ExportReportSplitDropdown from '@/components/swine/dropdowns/ExportReportDropdown.vue';
import { DropdownOption } from '@/models/swine/dropdown.model';
import DeleteReportModal from '@/components/swine/reports/DeleteReportModal.vue';
import moment from 'moment';
import { downloadPdf } from '@/utils/download-pdf/download-pdf';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { exportReportExcel } from '@/utils/export-excel/export-excel';
import { UserSessionStore } from '@/store/swine/user-session';
import { ProfileStores } from '@/store/swine/profile/index';
import { exportSummaryReportExcel } from '../../../utils/export-excel/export-excel';
import { ExportSummaryReportData } from '../../../models/swine/report/report.model';

@Component({
  components: {
    Page,
    SearchBar,
    Dropdown,
    ReportTable,
    ClientDropdown,
    CountryDropdown,
    FarmDropdown,
    FarmUnitDropdown,
    MonthDropdown,
    RegionDropdown,
    StateDropdown,
    YearDropdown,
    MockReportManagement,
    ExportReportSplitDropdown,
    DeleteReportModal,
  },
})
export default class ReportManagement extends Vue {
  store = ReportStores.list;
  totalReports = 0;
  filteredReports = 0;
  currentPageNumber = DEFAULT_PAGE_NUMBER;
  currentRowsPerPage = DEFAULT_COUNT_PER_PAGE;
  offsetValue = 0;
  currentFilter = '';
  search: string | null = null;
  sortBy = 'publishedDate:desc';
  // We can hard code these keys and its ids since we know the position of things
  keys = [
    `month`,
    `year`,
    `country`,
    `region`,
    `state`,
    `client`,
    `farm`,
    `farm_unit`,
  ];
  queries: { [key: string]: string | null } = {};
  monthKey = `month`;
  yearKey = `year`;
  countryKey = `country`;
  regionKey = `region`;
  stateKey = `state`;
  clientKey = `client`;
  farmKey = `farm`;
  farmUnitKey = `farm_unit`;
  reports: AuditReportDisplayValues[] = [];
  showNormalReports = true;
  selectedReports: AuditReportDisplayValues[] = [];
  exportReportOptions: DropdownOption[] = [];
  exportReportBorderColor = `#0072CE`;
  isClearAllFiltersAvailable = false;
  resetDetails: { [key: string]: boolean | null } = {};
  isModalActive = false;
  resetSelectedReports = false;
  areInvalidReportsSelected = false;
  reportType = ReportTypeStatus.NORMAL;
  currentRole = '';
  currentCountry = '';
  currentEmail = '';
  userSessionDetails = UserSessionStore.detail;
  isCurrentRoleViewer = false;
  userProfileStore = ProfileStores.profile;
  isCountryDropdownDisabled = false;
  countryLockedValue: string | null = null;

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      this.currentRole = this.userProfileStore.userProfile.role;
      this.isCurrentRoleViewer =
        this.currentRole === Role.Viewer ? true : false;
      this.currentCountry = this.userProfileStore.userProfile.country
        ? this.userProfileStore.userProfile.country.code
        : '';
      this.currentEmail = this.userProfileStore.userProfile.email;
      // If currentRole is not a regional, lock the country dropdown
      this.isCountryDropdownDisabled = this.currentRole !== Role.Regional;
      if (this.isCountryDropdownDisabled) {
        this.countryLockedValue =
          this.userProfileStore.userProfile.country.id.toString();
        this.updateCountryId(
          this.userProfileStore.userProfile.country.id.toString()
        );
      }
      // Set up the dictionary
      this.exportReportOptions = EXPORT_REPORT_OPTION;
      this.keys.map((key) => {
        this.queries[key] = null;
      });
      this.retrieveReports();
    }
  }

  @Watch('store.normalResponse')
  updateInformation() {
    if (this.store.normalResponse) {
      this.totalReports = this.store.normalResponse.totalCount;
      this.filteredReports = this.store.normalResponse.filteredCount;
      this.reports = [];
      this.store.normalResponse.reports.map((details) => {
        this.reports.push({
          auditDateTime: moment(details.publishedDate)
            .local()
            .format('DD/MM/YYYY hh:mm A'),
          farmUnitCodeName: details.reportValues.farmUnit.farmUnitCodeName,
          id: details.id,
          index: details.index,
          totalLungsObserved: details.reportValues.totalNumberOfLungs.length,
          auditorEmail: details.user.email ? details.user.email : '',
          auditorName: details.user.userProfile
            ? details.user.userProfile.name
            : '',
          country:
            typeof details.user.country !== 'string' && details.user.country
              ? details.user.country.code
              : '',
        });
      });
    }
  }

  searchReports(searchQuery: string) {
    this.search = null;
    if (searchQuery.length > 0) {
      this.search = searchQuery;
    }
    this.retrieveReports();
  }

  sortTable(sort: string) {
    this.sortBy = sort;
    this.retrieveReports();
  }

  updateRowCount(params: RowCountChange) {
    this.currentRowsPerPage = params.rowsPerPage;
    this.currentPageNumber = 1; // Reset it to prevent issues where we search from 21-10 (example)
    this.offsetValue = params.offsetValue;
    this.retrieveReports();
  }

  updatePageNumber(params: PageNumberChange) {
    this.currentPageNumber = params.pageNumber;
    this.offsetValue = params.offsetValue;
    this.retrieveReports();
  }

  retrieveReports() {
    if (this.queries) {
      const baseSearchParams: AuditReportSearchParams = {
        limit: this.currentRowsPerPage,
        offset: this.offsetValue,
        sortBy: this.sortBy,
        type: REPORT_TYPE.NORMAL,
        country: this.queries[this.countryKey],
        region: this.queries[this.regionKey],
        state: this.queries[this.stateKey],
        client: this.queries[this.clientKey],
        farm: this.queries[this.farmKey],
        farmUnit: this.queries[this.farmUnitKey],
        month: this.queries[this.monthKey],
        year: this.queries[this.yearKey],
        query: this.search,
      };
      this.store.getAllNormalAuditReports(baseSearchParams);
    }
  }

  resetQueryValues(keyIndex: number) {
    for (let index = keyIndex; index < this.keys.length; index++) {
      this.queries[this.keys[index]] = null;
    }
  }

  updateMonthId(value: string) {
    this.resetQueryValues(0);
    if (value.length > 0) {
      this.queries[this.monthKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  updateYearId(value: string) {
    this.resetQueryValues(1);
    if (value.length > 0) {
      this.queries[this.yearKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  updateCountryId(value: string) {
    this.resetQueryValues(2);
    if (value.length > 0) {
      this.queries[this.countryKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  updateRegionId(value: string) {
    this.resetQueryValues(3);
    if (value.length > 0) {
      this.queries[this.regionKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  updateStateId(value: string) {
    this.resetQueryValues(4);
    if (value.length > 0) {
      this.queries[this.stateKey] = value;
    }
    this.retrieveReports();
  }

  updateClientId(value: string) {
    this.resetQueryValues(5);
    if (value.length > 0) {
      this.queries[this.clientKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  updateFarmId(value: string) {
    this.resetQueryValues(6);
    if (value.length > 0) {
      this.queries[this.farmKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  updateFarmUnitId(value: string) {
    this.resetQueryValues(7);
    if (value.length > 0) {
      this.queries[this.farmUnitKey] = value;
    }
    this.showClearAllFilters();
    this.retrieveReports();
  }

  // We will need to reset the states of the dropdowns
  switchPages() {
    this.store.resetState();
    this.resetQueryValues(0);
    this.showClearAllFilters();
    this.showNormalReports = !this.showNormalReports;
    // We will need to manually fetch the normal reports
    if (this.showNormalReports) {
      this.retrieveReports();
    }
  }

  updateSelectedItems(reports: AuditReportDisplayValues[]) {
    this.selectedReports = reports;
    this.areInvalidReportsSelected = false;
    if (this.currentRole === Role.Regional) {
      this.areInvalidReportsSelected = false;
    } else if (this.currentRole === Role.Admin) {
      this.selectedReports.forEach((report) => {
        if (report.country !== this.currentCountry) {
          this.areInvalidReportsSelected = true;
        }
      });
    } else if (this.currentRole === Role.Auditor) {
      this.areInvalidReportsSelected = true;
    } else if (this.currentRole === Role.Viewer) {
      this.areInvalidReportsSelected = true;
    }
    this.showClearAllFilters();
  }

  async selectExportReportOption(option: DropdownOption) {
    // Export PDF
    if (option.value === 'export_pdf') {
      const reportPDFPromises: Promise<any>[] = [];
      reportPDFPromises.concat(
        this.selectedReports.map((report) => {
          return downloadPdf(report.id.toString());
        })
      );
      await Promise.all(reportPDFPromises);
    } else if (option.value === 'export_excel') {
      //Export Excel
      const reportIDStrings: string[] = [];
      for (const report of this.selectedReports) {
        reportIDStrings.push(`${report.id}`);
      }
      const response = await axios.get(`${Endpoints.Reports}/swine`, {
        params: {
          report: reportIDStrings.join(','),
          exportType: option.value,
        },
      });
      const reports: ExcelReportData[] = response.data;
      const isMockReport = false;
      exportReportExcel(reports, isMockReport);
    } else if (option.value === 'export_summary_excel') {
      // Export Excel summary
      const reportIDStrings: string[] = [];
      for (const report of this.selectedReports) {
        reportIDStrings.push(`${report.id}`);
      }
      const response = await axios.get(`${Endpoints.Reports}/swine`, {
        params: {
          report: reportIDStrings.join(','),
          exportType: option.value,
        },
      });
      const reports: ExportSummaryReportData[] = response.data;
      const isMockReport = false;
      exportSummaryReportExcel(reports, isMockReport);
    }
  }

  showClearAllFilters() {
    this.isClearAllFiltersAvailable = false;

    if (this.isCountryDropdownDisabled) {
      this.keys.forEach((key) => {
        if (key !== 'country' && this.queries[key]) {
          this.isClearAllFiltersAvailable = true;
        }
      });
    } else {
      this.keys.forEach((key) => {
        if (this.queries[key]) {
          this.isClearAllFiltersAvailable = true;
        }
      });
    }

    if (this.selectedReports.length > 0) {
      this.isClearAllFiltersAvailable = true;
    }
  }

  clearAllFilters() {
    this.keys.forEach((key) => {
      this.resetDetails[key] = true;
      this.queries[key] = null;
    });
    this.resetSelectedReports = true;
    this.search = null;
    if (this.isCountryDropdownDisabled && this.userProfileStore.userProfile) {
      this.updateCountryId(
        this.userProfileStore.userProfile.country.id.toString()
      );
    }
    this.retrieveReports();
  }

  updateResetMonthDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.monthKey] = null;
    }
  }

  updateResetYearDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.yearKey] = null;
    }
  }

  updateResetCountryDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.countryKey] = null;
    }
  }

  updateResetRegionDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.regionKey] = null;
    }
  }

  updateResetStateDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.stateKey] = null;
    }
  }

  updateResetClientDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.clientKey] = null;
    }
  }

  updateResetFarmDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.farmKey] = null;
    }
  }

  updateResetFarmUnitDetails(value: boolean) {
    if (value) {
      this.resetDetails[this.farmUnitKey] = null;
    }
  }

  deleteSelectedReports() {
    this.isModalActive = false;
    const reportIds: number[] = [];
    for (const report of this.selectedReports) {
      reportIds.push(report.id);
    }
    this.store.deleteSpecifiedReports(reportIds);
    this.resetSelectedReports = true;
  }

  @Watch('store.deleteResponse')
  retrieveNewReports() {
    this.retrieveReports();
  }
}
