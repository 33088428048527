













import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { CountryStores } from '@/store/swine/country/index';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Country } from '@/models/swine/country/country.model';
import { generateUniqueId } from '@/utils/lodash';

@Component({
  components: {
    Dropdown,
  },
})
export default class CountryDropdown extends Vue {
  store = CountryStores.list;
  countries: DropdownOption[] = [];
  displayValue = 'Country';
  selectedValue = '';

  @Prop({
    type: Boolean,
    default: false,
  })
  isDisabled!: boolean;

  @Prop({
    type: String,
    default: null,
  })
  defaultValue!: string;
  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  @Prop({
    type: String,
    default: 'Country',
  })
  textValue!: string;

  mounted() {
    this.createBaseData();
    this.displayValue = this.textValue;
    this.store.getAllCountries();
  }

  createBaseData() {
    this.countries = [];
    this.countries.push({
      text: `Country`,
      value: `-1`,
      uniqueKey: generateUniqueId(`country_`),
    });
  }

  @Watch(`defaultValue`)
  updateValue() {
    if (this.defaultValue) {
      this.selectedValue = this.defaultValue;
    }
  }
  @Watch(`store.response`)
  getCountries() {
    if (this.store.response) {
      this.countries = [];
      this.store.response.map((data: Country) => {
        this.countries.push({
          text: data.code,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.code}_`),
        });
      });
      this.updateValue();
    }
  }

  @Emit(`select`)
  selectCountry(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Country';
      if (this.isDisabled && this.countries) {
        const countryValue = this.countries.find((data) => {
          return data.value === this.selectedValue;
        });

        if (countryValue) {
          this.displayValue = countryValue.text;
        }
      }
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }
}
