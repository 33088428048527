import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { State } from '@/models/swine/states/state.model';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'state.list',
  store,
})
class StateListStore extends VuexModule {
  response: State[] | null = null;
  isLoading = false;
  error: any | null = null;
  regionId: string | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: State[]) {
    this.response = payload;
  }

  @Mutation
  setRegionId(regionId: string) {
    this.regionId = regionId;
  }

  @Action
  getAllStatesByRegionId(regionId: string) {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      if (regionId) {
        this.context.commit(`setRegionId`, regionId);
        axios
          .get(`${Endpoints.States}`, {
            params: {
              region: this.regionId,
            },
          })
          .then((response) =>
            this.context.commit('setResponse', response.data),
          );
      }
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(StateListStore);
