










import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import { RegionStores } from '@/store/swine/regions/index';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Region } from '@/models/swine/regions/region.model';
import { generateUniqueId } from '@/utils/lodash';
@Component({
  components: {
    Dropdown,
  },
})
export default class RegionDropdown extends Vue {
  store = RegionStores.list;
  regions: DropdownOption[] = [];
  displayValue = 'Region';

  @Prop({
    type: String,
    default: null,
  })
  countryId!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  resetDropdown!: boolean;

  mounted() {
    this.createBaseData();
  }

  createBaseData() {
    this.regions = [];
    this.regions.push({
      text: `Region`,
      value: `-1`,
      uniqueKey: generateUniqueId(`region_`),
    });
  }

  @Watch(`disabled`)
  @Watch(`countryId`)
  fetchData() {
    if (this.countryId && !this.disabled) {
      this.store.getAllRegionByCountryId(this.countryId);
    }
  }

  @Watch(`store.response`)
  getRegionsByCountryId() {
    if (this.store.response && this.regions) {
      this.regions = [];
      this.store.response.map((data: Region) => {
        this.regions.push({
          text: data.code,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.code}_`),
        });
      });
    }
  }

  @Emit(`select`)
  selectRegion(data: DropdownOption) {
    this.displayValue = data.text;
    return data.value;
  }

  @Watch('resetDropdown', { deep: true })
  resetDropdownOptions() {
    if (this.resetDropdown) {
      this.displayValue = 'Region';
      this.resetProcessComplete();
    }
  }

  @Emit('reset')
  resetProcessComplete() {
    return true;
  }
}
